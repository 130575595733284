<template>
  <v-container v-if="($store.state.Auth.token.claims.type!='admin' && $store.state.Auth.token.claims.type!='superuser')" class="safeMargin">
    <v-card class="mx-auto text-center px-0 pt-0 rounded-lg " width="100%">
      <v-card-title class="text-center text-h6 ">
        <v-spacer></v-spacer>


        <strong style="font-weight:300">Hola, </strong>


        <strong style="font-weight:500">{{ $store.state.Auth.token.claims.name.split(" ")[0] }}!</strong>

      <!--   <v-avatar class="ml-2">
          <UserImage :small="true" :user="userImg" />
        </v-avatar>
 -->

        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <!--         <v-divider class="mb-8"></v-divider>
 -->
        <WeeklyProgress :daysUsed="workoutsCompleted" :maxDays="user ? user.plan : 7"
          :workedOutToday="workedOutToday" />

<!-- 
          <v-btn block text class="mt-4" @click="$router.push('/entrenamiento')" v-show="user && user.routines && user.routines.length>0 || user&&  user.mainWorkout && user.mainWorkout.plan_id">
           <v-icon left>
              mdi-play
           </v-icon>
           Ir a entrenamiento
          </v-btn> -->

      </v-card-text>



    </v-card>



    <v-row class="mt-4" :no-gutters="$vuetify.breakpoint.xsOnly">
     <!--  <v-col cols="12" sm="6">
        <v-list class="rounded-lg mb-2 pa-0 overflow-hidden glass" >
          <v-list-item two-line @click="$router.push('/entrenamiento')">
            <v-list-item-avatar>
             <v-icon >
              mdi-play
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Rutinas</v-list-item-title>
              <v-list-item-subtitle>
                  Continua tu entrenamiento.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon color="primary">
                <v-icon>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col> -->

        <v-col cols="12" sm="6">
        <v-list class="rounded-lg mb-2 pa-0 overflow-hidden " >
          <v-list-item two-line @click="$router.push('/comunidad')">
            <v-list-item-avatar>
             <v-icon >
              mdi-bullhorn
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Comunidad</v-list-item-title>
              <v-list-item-subtitle>
                Mantente al tanto de las novedades.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon color="primary">
                <v-icon>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>


      <v-col cols="12" sm="6">
        <v-list class="rounded-lg mb-2 pa-0 overflow-hidden " >
          <v-list-item two-line @click="$router.push('/progreso')">
            <v-list-item-avatar>
             <v-icon >
              mdi-finance
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Controles</v-list-item-title>
              <v-list-item-subtitle>
                Sigue de cerca tu actividad.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon color="primary">
                <v-icon>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="12" sm="6">
        <v-list class="rounded-lg mb-2 pa-0 overflow-hidden">
          <v-list-item two-line @click="$router.push('/cronometro')">
            <v-list-item-avatar>
              <v-icon >
                mdi-timer
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Cronómetro</v-list-item-title>
              <v-list-item-subtitle>
                Mide tu tiempo de entrenamiento.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon color="primary">
                <v-icon>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
  <Activity v-else/>

</template>

<script>
import moment from 'moment';

import UserImage from '@/components/profile/UserImage.vue';
import WeeklyProgress from '@/components/training/WeeklyProgress.vue';


import Activity from '@/views/Activity.vue';

import {
  getFirestore, collection, query, where, getDocs, doc, getDoc
} from 'firebase/firestore';
import { logAuditEvent } from '@/error/audit.js';


export default {
  components: {
    UserImage,
    WeeklyProgress,
    Activity
  },
  data() {
    return {
      workoutsCompleted: -1,
      user: null,
      alert: false,
      workedOutToday: false,
      userImg: null,
    }
  },

  created() {

    this.userImg = { id: this.$store.state.Auth.token.claims.user_id }
  },

  async mounted() {
    this.$vuetify.goTo(0);

    logAuditEvent('read', this.$store.state.Auth.token.claims.user_id, `Loaded home page`);
    await this.getUser();
    this.workoutsCompleted = await this.getWorkoutsCompleted(this.$store.state.Auth.token.claims.user_id)

  },
  methods: {
    showData(route) {
      this.$router.push(route);
    },
    async getWorkoutsCompleted(user) {

      const db = getFirestore()
      const workoutsRef = collection(db, `workouts`);
      const startOfWeek = this.getStartOfWeek();
      const endOfWeek = this.getEndOfWeek();

      const q = query(workoutsRef, where("date", ">=", startOfWeek), where("date", "<", endOfWeek),
        where("user_id", "==", user)
      );
      const workoutDocs = await getDocs(q);

      let workoutsCompleted = workoutDocs.size
      const workoutDates = workoutDocs.docs.map(doc => doc.data().date.toDate().toISOString())

      const isSameDay = workoutDates.some(date => moment(date).isSame(moment(), 'day'))

      if (isSameDay) {
        this.workedOutToday = true
      }

      return workoutsCompleted
    },
    getStartOfWeek() {
      const now = new Date();
      let day = now.getDay();
      const diff = (day === 0 ? -6 : 1); // if it's Sunday, subtract 6, otherwise 1
      const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - day + diff, 0, 0, 0, 0);
      return startOfWeek;
    },
    getEndOfWeek() {
      const startOfWeek = this.getStartOfWeek();
      const endOfWeek = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + 6, 23, 59, 59, 999);
      return endOfWeek;
    },
    async getUser() {
      const db = getFirestore()
      let user = this.$store.state.Auth.token.claims.user_id;


      const docRef = doc(db, `users/${user}`);
      const docSnap = await getDoc(docRef);

      let data = docSnap.data()

      data.plan = parseInt(data.plan)

      if (data.plan == 0) {
        const db = getFirestore()
        let scheduleIntervalsRef = doc(db, `configurations/schedule`);
        let scheduleIntervals = await getDoc(scheduleIntervalsRef);
        let scheduleIntervalsData = scheduleIntervals.data()
        let maxDays = 0
        scheduleIntervalsData.Domingo.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Lunes.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Martes.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Miercoles.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Jueves.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Viernes.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Sabado.length > 0 ? maxDays += 1 : null

        data.plan = maxDays
      }

      this.user = data

      if (data.disabled) {
        let today = moment().toDate()
        await this.setAlert('user_disabled', today, null)
        this.$notify({
          group: "feedback",
          title: "Error",
          type: "error",
          text: "Tu usuario ha sido deshabilitado, contacta con el administrador.",
        });

        this.logout();

      }

      if (this.user.endOfSubscription) {
        let endOfSubscription = new Date(this.user.endOfSubscription.seconds * 1000)
        let today = moment().toDate()
        if (endOfSubscription < today) {
          //await this.setAlert('user_subscription_expired', today, null)
          this.$router.push({ name: 'suscripcion' })

        } else {
          //calculate diffs and if less than 5 days, show alert set this.alert=true

          let diff = moment(endOfSubscription).diff(today, 'days');
          if (diff> 0 && diff <= 5) {

            this.alert = diff;
          }

          if(diff<=0){
            //await this.setAlert('user_subscription_expired', today, null)
             this.$router.push({ name: 'suscripcion' })
          }
          
        }
      }

      let today = moment().toDate()

      //check if user is on an active licsense, for this it needs to check the user 'licensePaused' boolean property.
      if (this.user.licensePaused ) {
        //await this.setAlert('user_license_paused', today, null)
        this.$notify({
          group: "feedback",
          title: "Error",
          type: "error",
          text: "Estas en una licencia activa, en tu perfil puedes desactivar tu licencia. De lo contrario contacta con el administrador.",
        });
        this.$router.push('/perfil')
      }

      //and also search in the user 'licenses' collection for license between issuedOn and resumedOn datess.
      // Query Firestore for licenses issued before today
      const licensesRef = collection(db, `users/${user}/licenses`);
      const q = query(licensesRef, where("issuedOn", "<", today));

      const licenseDocs = await getDocs(q);
      const filteredLicenses = licenseDocs.docs
        .map(doc => doc.data())
        .filter(license => {
          if (license.resumedOn) {
            return new Date(license.resumedOn.seconds * 1000) > today;
          } else if (typeof license.resumedOn === 'undefined') {
            return true;
          }

        }); // Filter by resumedOn in client

      if (filteredLicenses.length === 0) {
        //console.log('No matching documents.');
      } else {
        this.$notify({
          group: "feedback",
          title: "Error",
          type: "error",
          text: "Estas en una licencia activa, en tu perfil puedes desactivar tu licencia.",
        });
        this.$router.push('/perfil')
      }





    },
  },
};
</script>

<style scoped>
.grid-item2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  color: #FFF;
  border-radius: 40px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  color: #FFF;
  border-radius: 40px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}
</style>
