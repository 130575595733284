import { render, staticRenderFns } from "./Activity.vue?vue&type=template&id=20a5219a&scoped=true"
import script from "./Activity.vue?vue&type=script&lang=js"
export * from "./Activity.vue?vue&type=script&lang=js"
import style0 from "./Activity.vue?vue&type=style&index=0&id=20a5219a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20a5219a",
  null
  
)

export default component.exports